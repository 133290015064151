import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import './Proposal.css';
import './App.css';

const Proposal = () => {
    useEffect(() => {
        // Scroll to the top when component mounts
        window.scrollTo(0, 0);
    }, []);

    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [relationError, setRelationError] = useState(null);
    const [addressLine1Error, setAddressLine1Error] = useState(null);
    const [addressLine2Error, setAddressLine2Error] = useState(null);
    const [postCodeError, setPostCodeError] = useState(null);
    const [lotError, setLotError] = useState(null);
    const [commentsError, setCommentsError] = useState(null);
    const [submitButtonText, setSubmitButtonText] = useState("Submit");
    const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
    const [unsubmitted, setUnsubmitted] = useState(true);
    const [showHeading, setShowHeading] = useState(true);

    const handleSubmit = () => {
        let name = document.getElementById("proposalName").value.substring(0, 50);
        let email = document.getElementById("proposalEmail").value.toLowerCase().substring(0, 100);
        let phone = document.getElementById("proposalPhone").value.toLowerCase().substring(0, 20);
        let relation = document.getElementById("proposalRelation").value;
        let addressLine1 = document.getElementById("proposalAddress1").value.substring(0, 100);
        let addressLine2 = document.getElementById("proposalAddress2").value.substring(0, 100);
        let postCode = document.getElementById("proposalPostCode").value.replace(/\D/g, '');
        let numberOfLots = document.getElementById("proposalLotCount").value.trim().replace(/,/g, '');
        let comments = document.getElementById("proposalComments").value.substring(0, 3000);
        validateSubmit(name, email, phone, relation, addressLine1, addressLine2, postCode, numberOfLots, comments);
    }

    const validateSubmit = (name, email, phone, relation, addressLine1, addressLine2, postCode, numberOfLots, comments) => {
        if (!name || name.length < 2 || name.length > 50) {
            setNameError(true);
            return;
        }
        setNameError(false);
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!email || !email.match(emailRegex)) {
            setEmailError(true);
            return;
        }
        setEmailError(false);

        const phoneRegex = /^(?=.*\d)[\d\s\+\(\)-]{0,}(?:\d[\d\s\+\(\)-]{0,13})?$/;
        if (!phone || !phone.match(phoneRegex)) {
            setPhoneError(true);
            return;
        }
        setPhoneError(false);

        if (relation !== "Owner" && relation !== "Tenant" && relation !== "Other") {
            setRelationError(true);
            return;
        }
        setRelationError(false);

        if (!addressLine1 || addressLine1.length < 1 && addressLine1.length > 100) {
            setAddressLine1Error(true);
            return;
        }
        setAddressLine1Error(false);

        if (addressLine2.length > 100) {
            setAddressLine2Error(true);
            return;
        }
        setAddressLine2Error(false);

        const postCodeRegex = /^[3-9]\d{3}$/;
        if (!postCode || !postCode.match(postCodeRegex)) {
            setPostCodeError(true);
            return;
        }
        setPostCodeError(false);

        const lotsRegex = /^[1-9]\d{0,4}$/;
        if (!numberOfLots || !numberOfLots.match(lotsRegex) || parseInt(numberOfLots, 10) <= 0) {
            setLotError(true);
            return;
        }
        setLotError(false);

        if (comments.length > 3000) {
            setCommentsError(true);
            return;
        }
        setCommentsError(false);

        submitProposal(name, email, phone, relation, addressLine1, addressLine2, postCode, numberOfLots, comments);
    }

    const submitProposal = async (name, email, phone, relation, addressLine1, addressLine2, postCode, numberOfLots, comments) => {
        try {
            const response = await axios.post(
                "https://e6i71hxxdg.execute-api.ap-southeast-2.amazonaws.com/dev",
                {
                    name: name,
                    email: email,
                    phone: phone,
                    relation: relation,
                    addressLine1: addressLine1,
                    addressLine2: addressLine2,
                    postCode: postCode,
                    numberOfLots: numberOfLots,
                    comments: comments
                }
            );

            if (response.status === 200) {
                setUnsubmitted(false);
                setSubmissionSuccessful(true);
                setShowHeading(false);
            } else {
                console.log('Error submitting');
            }
        } catch (error) {
            console.error("Error submitting proposal:", error);
        }
    }

    const renderSuccessfulSubmission = () => {
        return (
            <div style={{ padding: "3rem" }}>
                <Card id="pageCardDark" style={{ margin: "auto" }}>
                    <Card.Body id="pageCardTransparentBody">
                        <span id="pageCardTransparentBodyText">Thanks very much for considering us to be your new Owners Corporation manager. We'll be in touch shortly to discuss further.</span>
                    </Card.Body>
                </Card>
            </div>
        );
    }

    const renderSubmissionForm = () => {
        return (
            <div class="pageFormContainer">
                <div id="proposalFormContainer">
                    <span id="proposalHeading">Contact Details</span>
                    <Form id="proposalForm">
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" className="proposalFormInput" id="proposalName" />
                            {nameError ?
                                <div>
                                    <span className="proposalErrorPrompt">Name required</span>
                                </div> : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" className="proposalFormInput" id="proposalEmail" />
                            {emailError ?
                                <div>
                                    <span className="proposalErrorPrompt">Invalid email address</span>
                                </div> : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="email" className="proposalFormInput" id="proposalPhone" />
                            {phoneError ?
                                <div>
                                    <span className="proposalErrorPrompt">Invalid phone number</span>
                                </div> : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Relation to the property</Form.Label>
                            <Form.Select className="proposalFormInput" id="proposalRelation">
                                <option>Select</option>
                                <option>Owner</option>
                                <option>Tenant</option>
                                <option>Other</option>
                            </Form.Select>
                            {relationError ?
                                <div>
                                    <span className="proposalErrorPrompt">Please select an option</span>
                                </div> : null}
                        </Form.Group>

                        <span id="proposalHeading">Property Details</span>
                        <Form.Group className="mb-3">
                            <Form.Label>Address Line 1</Form.Label>
                            <Form.Control type="text" className="proposalFormInput" id="proposalAddress1" />
                            {addressLine1Error ?
                                <div>
                                    <span className="proposalErrorPrompt">Invalid address</span>
                                </div> : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Address Line 2</Form.Label>
                            <Form.Control type="text" className="proposalFormInput" id="proposalAddress2" />
                            {addressLine2Error ?
                                <div>
                                    <span className="proposalErrorPrompt">Invalid address</span>
                                </div> : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>State</Form.Label>
                            <Form.Control placeholder="VIC" disabled className="proposalFormInput" id="proposalState" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Post Code</Form.Label>
                            <Form.Control type="number" className="proposalFormInput" id="proposalPostCode" />
                            {postCodeError ?
                                <div>
                                    <span className="proposalErrorPrompt">Invalid post code</span>
                                </div> : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Number of Lots</Form.Label>
                            <Form.Control type="number" className="proposalFormInput" id="proposalLotCount" />
                            {lotError ?
                                <div>
                                    <span className="proposalErrorPrompt">Please enter the amount of lots in digits</span>
                                </div> : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Comments</Form.Label>
                            <Form.Control as="textarea" className="proposalFormInput" id="proposalComments" />
                            {commentsError ?
                                <div>
                                    <span className="proposalErrorPrompt">Please keep your comment under 3000 characters, or email us at admin@ownersfirst.com.au</span>
                                </div> : null}
                        </Form.Group>
                    </Form>
                    <div id="proposalSubmitButtonContainer">
                        <Button id="proposalSubmitButton" type="submit" onClick={handleSubmit}>{submitButtonText}</Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            {showHeading ? <div>
                <div class="pageHeadingContainer">
                    <span class="pageHeading">Request management</span>
                </div>
                <div class="pageHeadingUnderContainer">
                    <span class="pageHeadingUnder">Empower your property with our industry leading solutions by requesting our services today.</span>
                </div></div> : null}
            <div id="proposalFormWrapper">
                {submissionSuccessful ? renderSuccessfulSubmission() : null}
                {unsubmitted ? renderSubmissionForm() : null}
            </div>
        </div>
    );
}

export default Proposal;