import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import homeHero from './assets/homeHero.jpg';
import { BsKeyFill, BsFillPeopleFill, BsFillHouseFill } from 'react-icons/bs';
import './App.css';
import './Home.css'
import './Slide.css'
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // Scroll to the top when component mounts
        window.scrollTo(0, 0);
    }, []);

    const featureCardData = [
        {
            icon: <BsKeyFill class="pageCardIcon" />,
            title: "We say it, we do it",
            text: "We strongly believe in following through with our promises. If we say it, we do it. The benchmark for customer service starts with us"
        },
        {
            icon: <BsFillPeopleFill class="pageCardIcon" />,
            title: "Putting Owners First",
            text: "Our foundation is exceptional customer service. We prioritise your needs to deliver the strongest results"
        },
        {
            icon: <BsFillHouseFill class="pageCardIcon" />,
            title: "Everything an Owners Corporation should be",
            text: "We work for you, the owners. We listen to your needs and empower you to achieve your goals"
        }
    ];

    const reviewData = [
        {
            quote: "Owners First truly lives up to its name! The team's dedication to putting owners at the forefront is evident in their prompt and efficient service. A pleasure to have them managing our property."
        },
        {
            quote: "Choosing Owners First for our Owners Corporation was a game-changer. Their proactive approach, clear communication, and genuine concern for owners' needs make them a standout team."
        },
        {
            quote: "Owners First has been a breath of fresh air for our community. Their commitment to prioritising owners' interests, coupled with their professionalism, has significantly enhanced our overall property management experience."
        }
    ];

    const renderFeatureCards = () => {
        return featureCardData.map(({ icon, title, text }) => {
            return (
                <li>
                    <Card id="pageCardTransparent">
                        <Card.Body id="pageCardTransparentBody">
                            <div class="pageCardIconBackground">{icon}</div>
                            <Card.Title id="pageCardTransparentTitle">{title}</Card.Title>
                            <Card.Text id="pageCardTransparentBodyText">{text}</Card.Text>
                        </Card.Body>
                    </Card>
                </li>
            )
        })
    }

    const renderReviews = () => {
        return reviewData.map(({ author, job, quote }) => {
            return (
                <li>
                    <Card id="reviewCard">
                        <Card.Body id="pageCardTransparentBody">
                            <span id="reviewText">{quote}</span>
                        </Card.Body>
                    </Card>
                </li>
            )
        });
    }

    const handleRequestManagementClick = () => {
        navigate('/proposal');
    }

    return (
        <div>
            <div class="pageHeadingContainer">
                <span class="pageHeading">Built to put <span class="pageHeadingOrange">Owners First</span></span>
            </div>
            <div class="pageHeadingUnderContainer">
                <span class="pageHeadingUnder">Revolutionising Owners Corporation Management in Melbourne.</span>
            </div>
            <div class="pageHeadingButtonContainer">
                <Button id="pageButton" onClick={handleRequestManagementClick}>Request management</Button>
            </div>
            <div class="pageMainPhotoContainer">
                <img class="pageMainPhoto" src={homeHero} />
            </div>
            <div class="slideInFromLeft">
                <div class="pageSubHeadingContainer">
                    <span class="pageSubHeading">Less about corporate, more about people</span>
                </div>
                <div class="pageHeadingUnderContainer">
                    <span class="pageHeadingUnder">We believe in putting people first. Through exceptional service and enhanced accessibility, we are redefining the perception of the body corporate industry. Join us on a journey of prioritising your needs and experience a new level of Owners Corporation Management.</span>
                </div>
            </div>
            <div class="slideInFromRight">
                <div class="pageCardContainer">
                    <ul class="cardContainer2 wrap">
                        {renderFeatureCards()}
                    </ul>
                </div>
            </div>
            <div class="slideInFromBottom">
                <div class="pageSubHeadingContainer">
                    <span class="pageSubHeading">See what the community says about us</span>
                </div>
                <div class="pageCardContainer">
                    <ul class="cardContainer2 wrap">
                        {renderReviews()}
                    </ul>
                </div>
            </div>
        </div>

    );
}

export default Home;