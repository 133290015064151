import React from 'react';
import { Navbar, Nav, Container, Offcanvas, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { ReactComponent as Icon } from './assets/owners-first-logo.svg';
import './Navigation.css';
import { useNavigate } from 'react-router-dom';

const Navigation = () => {
    const navigate = useNavigate();
    const handleClick = (page) => {
        navigate(`/${page}`)
    }

    return (
        <Navbar bg="light" expand="lg" id="navbar">
            <Container fluid>
                <Navbar.Brand href="/"><span id="navbarLogo"><Icon /></span></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        navbarScroll
                    >
                        <Nav.Link onClick={() => handleClick('')}>Home</Nav.Link>
                        <Nav.Link onClick={() => handleClick('services')}>Services</Nav.Link>
                        <Nav.Link onClick={() => handleClick('portfolio')}>Portfolio</Nav.Link>
                        <Nav.Link onClick={() => handleClick('about')}>About us</Nav.Link>
                        <Nav.Link onClick={() => handleClick('forms')}>Forms</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;